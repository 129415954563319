import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['menu', 'hamburger']

  connect(){
    this.state = 'closed'
  }

  toggle(e){

    this.hamburgerTarget.classList.toggle('block')
    this.hamburgerTarget.classList.toggle('hidden')

    if(this.state == 'closed'){
      this.menuTarget.classList.remove('hidden')
      this.menuTarget.classList.add('duration-150')
      this.menuTarget.classList.remove('ease-in')
      this.menuTarget.classList.add('ease-out')
      this.menuTarget.classList.remove('opacity-0')
      this.menuTarget.classList.add('opacity-100')
      this.menuTarget.classList.remove('scale-95')
      this.menuTarget.classList.add('scale-100')

    } else {
      this.menuTarget.classList.add('duration-150')
      this.menuTarget.classList.remove('ease-out')
      this.menuTarget.classList.add('ease-in')
      this.menuTarget.classList.remove('opacity-100')
      this.menuTarget.classList.add('opacity-0')
      this.menuTarget.classList.remove('scale-100')
      this.menuTarget.classList.add('scale-95')
      this.menuTarget.classList.add('hidden')
    }
    this.state = this.state == 'closed' ? 'open' : 'closed'

  }
}
