
        import * as module0 from './controllers/demo_controller.js';import * as module1 from './controllers/mobile_controller.js';import * as module2 from './controllers/modal_controller.js';import * as module3 from './controllers/switch_controller.js';import * as module4 from './controllers/typewriter_controller.js'
        const modules = {
            "./controllers/demo_controller.js": module0,
            "./controllers/mobile_controller.js": module1,
            "./controllers/modal_controller.js": module2,
            "./controllers/switch_controller.js": module3,
            "./controllers/typewriter_controller.js": module4,
        };
        export default modules;
      