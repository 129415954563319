import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    texts: Array,
    forward: { type: Number, default: 100 },
    backward: { type: Number, default: 50 },
    pause: { type: Number, default: 1000 },
    restart: { type: Number, default: 100 },
    loop: { type: Boolean, default: true },
    cursor: { type: Boolean, default: true }
  }

  connect(){
    // PROPERTIES & FLAGS
    this.current = 0;      // current text
    this.pointer = 0;      // current character
    this.direction = true; // true forward, false backward
    this.draw = true;      // continue to "type text"?

    if (this.cursorValue) { this.element.classList.add("typewriter-cursor"); }

    this.timer = setInterval(() => {
      this.run();
    }, this.forwardValue);
  }

  disconnect(){
    this.stop()
  }

  stop(){
    clearInterval(this.timer);
  }

  run(){
    let currentText = this.textsValue[this.current]
    if (this.direction) {
      this.pointer++;
      this.draw = this.pointer <= currentText.length;
    } else {
      this.pointer--;
      this.draw = this.pointer >= 0;
    }

    if (this.draw) {
      this.element.innerHTML = currentText.substring(0, this.pointer);
    } else {
      this.stop()
      this.direction = !this.direction;

      if (this.direction) {
        this.current++;
        if (this.loopValue && this.current == this.textsValue.length) {
          this.current = 0;
        }
        if (this.current <= this.textsValue.length) {
          this.timer = setTimeout(() => {
            this.timer = setInterval(() => { this.run() }, this.forwardValue);
          }, this.restartValue);
        }
      } else {
        this.timer = setTimeout(() => {
          this.timer = setInterval(() => { this.run() }, this.backwardValue);
        }, this.pauseValue);
      }
    }
  }

}