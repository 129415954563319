import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['backdrop', 'content', 'autoclose', 'topbanner', 'topbannerContent']

  connect(){
    if(this.getQueryVariable('ref') == 'theknowledge'){
      document.getElementById("turbo-topbanner-content").innerHTML = ""
      fetch('/topbanners/theknowledge/', {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }

  autocloseTargetConnected(element){
    this.close()
    element.focus()
  }

  topbannerContentTargetConnected(element){
    this.topbannerTarget.classList.remove('hidden')
  }

  open(e = null){
    if(e !== null){
      e.preventDefault()
      e.stopPropagation()
    }
    this.backdropTarget.classList.remove('hidden')

    this.backdropTarget.classList.remove('ease-in')
    this.backdropTarget.classList.remove('duration-200')
    this.backdropTarget.classList.remove('opacity-0')

    this.backdropTarget.classList.add('ease-out')
    this.backdropTarget.classList.add('duration-300')
    this.backdropTarget.classList.add('opacity-100')

    this.contentTarget.classList.remove('ease-in')
    this.contentTarget.classList.remove('duration-200')
    this.contentTarget.classList.remove('opacity-0')
    this.contentTarget.classList.remove('translate-y-4')
    this.contentTarget.classList.remove('sm:translate-y-0')
    this.contentTarget.classList.remove('sm:scale-95')

    this.contentTarget.classList.add('ease-out')
    this.contentTarget.classList.add('duration-300')
    this.contentTarget.classList.add('opacity-100')
    this.contentTarget.classList.add('translate-y-0')
    this.contentTarget.classList.add('sm:scale-100')

    document.body.addEventListener('keydown', event => this.keyPressToClose(event));
  }

  close(e){
    if(e !== undefined){
      e.preventDefault()
      e.stopPropagation()
    }

    this.backdropTarget.classList.remove('ease-out')
    this.backdropTarget.classList.remove('duration-300')
    this.backdropTarget.classList.remove('opacity-100')

    this.backdropTarget.classList.add('ease-in')
    this.backdropTarget.classList.add('duration-200')
    this.backdropTarget.classList.add('opacity-0')

    this.contentTarget.classList.remove('ease-out')
    this.contentTarget.classList.remove('duration-300')
    this.contentTarget.classList.remove('opacity-100')
    this.contentTarget.classList.remove('translate-y-0')
    this.contentTarget.classList.remove('sm:scale-100')

    this.contentTarget.classList.add('ease-in')
    this.contentTarget.classList.add('duration-200')
    this.contentTarget.classList.add('opacity-0')
    this.contentTarget.classList.add('translate-y-4')
    this.contentTarget.classList.add('sm:translate-y-0')
    this.contentTarget.classList.add('sm:scale-95')

    document.body.removeEventListener('keydown', event => this.keyPressToClose(event));

    setTimeout(() => {
      this.backdropTarget.classList.add('hidden')
    }, 200);
  }

  keyPressToClose(e) {
    if(e.key === "Escape") {
      this.close(e)
    }
  }

  trigger(e){
    e.preventDefault()
    document.getElementById("turbo-modal-content").innerHTML = ""
    fetch(e.currentTarget.getAttribute("href"), {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))

    this.open(e)
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
  }

  closeTopbanner(e){
    e.preventDefault()
    e.stopPropagation()
    this.topbannerTarget.classList.add('hidden')
  }
}