import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'checkbox', 'switcher', 'background', 'onChecked', 'onUnchecked', 'helperOn', 'helperOff' ]

  connect(){
    this.update()
  }

  toggle(){
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    this.update()
  }

  update(){
    console.log('update')
    if(this.checkboxTarget.checked){
      this.backgroundTarget.classList.remove('bg-gray-200')
      this.backgroundTarget.classList.add('bg-emerald-400')
      this.switcherTarget.classList.remove('translate-x-0')
      this.switcherTarget.classList.add('translate-x-5')
    } else {
      this.backgroundTarget.classList.remove('bg-emerald-400')
      this.backgroundTarget.classList.add('bg-gray-200')
      this.switcherTarget.classList.remove('translate-x-5')
      this.switcherTarget.classList.add('translate-x-0')
    }
    if(this.hasOnCheckedTarget){
      Array.from(this.onCheckedTargets).forEach((item) => {
        item.classList.add(this.checkboxTarget.checked ? 'block' : 'hidden')
        item.classList.remove(this.checkboxTarget.checked ? 'hidden' : 'block')
      })
    }
    if(this.hasOnUncheckedTarget){
      Array.from(this.onUncheckedTargets).forEach((item) => {
        item.classList.add(this.checkboxTarget.checked ? 'hidden' : 'block')
        item.classList.remove(this.checkboxTarget.checked ? 'block' : 'hidden')
      })
    }
    if(this.hasHelperOnTarget){
      this.helperOnTarget.classList.add(this.checkboxTarget.checked ? 'block' : 'hidden')
      this.helperOnTarget.classList.remove(this.checkboxTarget.checked ? 'hidden' : 'block')
    }
    if(this.hasHelperOffTarget){
      this.helperOffTarget.classList.add(this.checkboxTarget.checked ? 'hidden' : 'block')
      this.helperOffTarget.classList.remove(this.checkboxTarget.checked ? 'block' : 'hidden')
    }
  }
}
