import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["frame", "missingReport", "withReport"]

  connect(){
    const urlParams = new URLSearchParams(window.location.search);
    const report = urlParams.get("ref");
    if (this.hasFrameTarget && report) {
      this.frameTarget.src = `${this.frameTarget.dataset["src"]}/${report}`;
    } else {
      this.missingReportTargets.forEach((el) => el.classList.remove("hidden"))
      this.withReportTargets.forEach((el) => el.classList.add("hidden"))
    }
  }
}